import React, { ChangeEvent, useState, ChangeEventHandler } from 'react'

import Heading, { Types } from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'

import styles from './form-success.module.scss'

const FormSuccess = () => {
    return (
        <div className={styles.container}>
            <Heading className={styles.heading} type={Types.H2} text="Thanks for your message!" />
            <Text>We've received your enquiry and we'll get back to you soon.</Text>
        </div>
    )
}

export default FormSuccess
