import React, { useState, ChangeEvent, FormEvent } from 'react'


import Heading from '@components/atoms/heading/heading'
import Text from '@components/atoms/text/text'
import Button, { Icons } from '@components/atoms/button/button'
import Metadata from '@components/atoms/metadata/metadata'

import FormSuccess from '@components/molecules/form-success/form-success'
import Breadcrumbs from '@components/molecules/breadcrumbs/breadcrumbs'

import Container from '@layouts/container/container'

import styles from './contact.module.scss'

interface FormData {
    name: string
    email: string
    phone?: string
    message: string
}

const ContactPage = ({ location, pageContext: { courses } }) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formData, setFormData] = useState<FormData>({} as FormData)
    const [formError, setFormError] = useState(false)

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { name, email, message } = formData
        if (!name || !email || !message) {
            setFormError(true)
            return
        }

        const res = await fetch('/.netlify/functions/sendEmail', {
            method: 'post',
            body: JSON.stringify(formData)
        })

        if (res.status === 200) {
            setFormSubmitted(true)
        } else {
            setFormError(true)
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const breadcrumbs = [{ label: 'Get in touch' }]
    return (
        <Container courses={courses}>
            <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
            <Metadata url={location?.href} title="MW Training | Get in touch" />
            <div className={styles.container}>
                <Heading className={styles.heading} text="Get in touch" />
                <Text className={styles.text}>
                    Give us a call on <a href="tel:07368880335">07368880335</a>
                </Text>
                <Text className={styles.text}>Or complete the form below and we'll get back to you</Text>
                {!formSubmitted && (
                    <div>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            {formError && <Text className={styles.error}>Please complete all required fields</Text>}
                            <label htmlFor="name">
                                Name <span>*</span>
                            </label>
                            <input onChange={handleChange} id="name" name="name" className={styles.input} placeholder="Name" />
                            <label htmlFor="email">
                                Email <span>*</span>
                            </label>
                            <input
                                onChange={handleChange}
                                id="email"
                                name="email"
                                inputMode="email"
                                className={styles.input}
                                placeholder="Email"
                            />
                            <label htmlFor="phone">Phone</label>
                            <input
                                onChange={handleChange}
                                id="phone"
                                name="phone"
                                inputMode="tel"
                                className={styles.input}
                                placeholder="Phone"
                            />
                            <label htmlFor="message">
                                Message <span>*</span>
                            </label>
                            <textarea
                                onChange={handleChange}
                                id="message"
                                name="message"
                                rows={5}
                                className={styles.input}
                                placeholder="Message"
                            ></textarea>
                            <Button aria-label="Submit form" type="submit" text="Send Message" icon={Icons.Send} />
                        </form>
                    </div>
                )}
                {formSubmitted && <FormSuccess />}
            </div>
        </Container>
    )
}

export default ContactPage
